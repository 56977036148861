import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAward } from '../../models/Award';
import {
  AwardBlock,
  ImgDiv,
  AwardImg,
  TextDiv,
  TitleDiv,
  AwardTitle,
  AwardSubTitle,
  AwardText,
  TitleRow,
  TitleWidth,
  PageTitle,
  PageContainer,
  Inner,
  InnerRow,
} from './style';

function AwardItem({ award: { title, subTitle, grantedBy, image = '' } }) {
  return (
    <AwardBlock>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ImgDiv>
          <AwardImg
            src={`${process.env.REACT_APP_MEDIA_DOMAIN.replace('static/media', '')}${
              image.includes('static') ? '' : 'static'
            }${image}`}
          />
        </ImgDiv>
        <TextDiv>
          <TitleDiv>
            <AwardTitle>
              {title}
              {subTitle && (
                <>
                  <br />
                  <AwardSubTitle>{subTitle}</AwardSubTitle>
                </>
              )}
            </AwardTitle>
          </TitleDiv>
          <AwardText>
            <br />
            {grantedBy}
          </AwardText>
        </TextDiv>
      </div>
    </AwardBlock>
  );
}

const Awards = () => {
  const { awards } = useAward();
  const { t } = useTranslation();
  const padBlocks = [...Array(4 - (awards.length % 4))].map((e, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <AwardBlock key={`Dummy${i}`} />
  ));

  return (
    <div style={awards.length === 0 ? { height: '100vh' } : { flex: 1 }}>
      <TitleRow>
        <TitleWidth>
          <PageTitle>{t('header.awards')}</PageTitle>
        </TitleWidth>
      </TitleRow>
      <PageContainer>
        <Inner>
          <InnerRow>
            {awards.map((award) => (
              <AwardItem award={award} key={award.id} />
            ))}
            {padBlocks}
          </InnerRow>
        </Inner>
      </PageContainer>
    </div>
  );
};

export default withRouter(Awards);
