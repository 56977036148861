import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import useStaticInfo from '../../models/StaticInfo';

import {
  PromotionBar,
  HomeMap,
  FPS,
  OnlineBooking,
  HomeNews,
  HomeOurCompany,
  HomeAwards,
} from './HomepageComponents';
import FloatingIcon from './HomepageComponents/FloatingIcon';

function Home() {
  const { getStaticValue, language, StaticInfoContext } = useStaticInfo('HomePage');

  const staticInfo = useContext(StaticInfoContext);

  const data = useMemo(
    () =>
      getStaticValue(
        [
          'promotion_image',
          'promotion_title',
          'promotion_content',
          'promotion_button_title',
          'promotion_overlay_opacity',
          'promotion_overlay_color',
          'promotion_button_url',
          'booking_title',
          'booking_imonthly_introduction',
          'booking_imonthly_url',
          'booking_ihourly_introduction',
          'booking_tagline',
          'booking_ihourly_url',
          'floating_icon_url',
          'destination_url',
        ],
        staticInfo
      ),
    [staticInfo, language]
  );

  return (
    <div id="Home" style={{ position: 'relative' }}>
      <PromotionBar />
      <HomeMap />
      <FPS data={data} />
      <OnlineBooking data={data} />
      <HomeNews />
      <HomeOurCompany data={data} />
      <HomeAwards />
      <FloatingIcon data={data} />
    </div>
  );
}
export default withRouter(Home);
