import styled from 'styled-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Search, Menu } from '../../General';
import icon from '../../../media/icons/mobile.png';
// import logo from '../../../media/images/logo_WP.png';
import '../Search.css';
// import MobileSlideMenu from './MobileSlideMenu';
import searchicon from '../../../media/icons/search.png';
import UpperItem from './UpperItem';
import { useHistory, Redirect } from 'react-router-dom';

const Container = styled.div`
  padding: 0 15px;
  min-height: 40px;
  clear: both;
  position: relative;
  overflow: hidden;
  background: #222222;
  color: #bbbbbb;
  @media (max-width: 980px) {
    display: none;
  }
`;

const InnerWidth = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  clear: both;
  height: 40px;
`;

const InnerRow = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
`;

const LeftBlock = styled.div`
  float: left;
`;

const LeftBlcokInner = styled.div`
  font-size: 0;
  line-height: 40px;
  text-align: left;
  vertical-align: middle;
`;

const MobileApp = styled.div`
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  vertical-align: middle;
  font-size: 0.625rem;
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
`;

const MobileAppA = styled.a`
  padding: 5px 0.61875rem;
  text-decoration: inherit;
  color: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  font: inherit;
  text-overflow: inherit;

  &:hover {
    color: #fff;
    text-decoration-line: none;
  }
`;

const MobileAppIconDiv = styled.span`
  display: inline;
  line-height: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
  margin-left: 0;
`;

const Mobileicon = styled.img`
  width: auto !important;
  height: 15px !important;
  margin-top: 1px !important;
  display: inline;
  vertical-align: text-bottom;
`;

const SearchRow = styled.div`
  display: inline-block;
  vertical-align: middle;
  vertical-align: middle;
  font-size: 0.625rem;
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #665f56;
  position: relative;
  overflow: hidden;
`;

const SearchInput = styled.input`
  padding: 1px 28px 0 12px;
  border: none;
  background: #222222;
  font-size: 12px;
  width: 150px;
  height: 30px;
  color: #bbbbbb;

  &:hover {
    color: #bbbbbb;
  }
  :focus {
    color: #bbbbbb;
  }
`;

const SearchForm = styled.form`
  display: flex;
`;

const SearchSubmitDiv = styled.div``;

const SearchSubmit = styled.input`
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
`;

const SearchImg = styled.img`
  position: absolute;
  top: 9px;
  right: 10px;
  width: 12px;
  height: 12px;
  pointer-events: none;
`;

const RightBlock = styled.div`
  float: right;
`;

const RightBlockInner = styled.div`
  line-height: 40px;
  font-size: 0;
  text-align: right;
  display: flex;
`;

function UpperHeader({ changeLanguage, pathname }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [keyWords, setKeyWords] = useState();

  function changeUrl() {
    const currentUrl = window.location.href.replace(/^.*\/\/[^\/]+/, '');
    if (currentUrl.includes('/en')) {
      return currentUrl.replace('/en', '/zh');
    }
    if (pathname.includes('/zh')) {
      return currentUrl.replace('/zh', '/en');
    }
    return `/${i18n.language}${currentUrl}`;
  }

  const navItems = [
    { link: `/${i18n.language}/news/index`, title: 'header.news' },
    { link: `/${i18n.language}/our-company`, title: 'header.our_company' },
    { link: `/${i18n.language}/awards/index`, title: 'header.awards' },
    {
      link: `/${i18n.language}/customer_enquiries/index`,
      title: 'header.customer_service',
    },
    {
      link: `/${i18n.language}/business_enquiries/index`,
      title: 'header.business_enquiries',
    },
    {
      link: changeUrl,
      title: 'header.change_lang',
      onClick: changeLanguage,
    },
  ];

  function handelSearch(e) {
    if (!keyWords) return;
    if (e.key === 'Enter' || e.type === 'click') {
      history.push({
        pathname: `/${i18n.language}/searches/${keyWords}`,
      });
    }
  }

  return (
    <div>
      <Container>
        <InnerWidth>
          <InnerRow>
            <LeftBlock>
              <LeftBlcokInner>
                <SearchRow>
                  <SearchForm method="get" action="">
                    <SearchInput
                      value={keyWords}
                      placeholder={t('header.search')}
                      onChange={(e) => {
                        const value = e.target.value || '';

                        setKeyWords(value);

                        // setKeyWords(value.replace(/[^A-Za-z0-9\s]+/, ''));
                      }}
                      onKeyPress={handelSearch}
                    />
                    <SearchSubmitDiv>
                      <SearchSubmit onClick={handelSearch} />
                      <SearchImg src={searchicon} />
                    </SearchSubmitDiv>
                  </SearchForm>
                </SearchRow>
                <MobileApp>
                  <MobileAppA href={`/${i18n.language}/mobile-app`} target="_blank">
                    <MobileAppIconDiv>
                      <Mobileicon src={icon} />
                    </MobileAppIconDiv>
                    {t('header.MobileApp')}
                  </MobileAppA>
                </MobileApp>
              </LeftBlcokInner>
            </LeftBlock>
            <RightBlock>
              <RightBlockInner>
                {navItems.map((record) => (
                  <UpperItem detail={record} key={record.title} />
                ))}
              </RightBlockInner>
            </RightBlock>
          </InnerRow>
        </InnerWidth>
      </Container>
    </div>
  );
}

export default UpperHeader;
