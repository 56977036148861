import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/zh-hk';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { usePickupLocations } from './Cart';
import { CustomerContext } from './User';
import { useCustomer } from './Customer';

const iMonthlyUrl = (id) => `${process.env.REACT_APP_API_DOMAIN}/Booking?CustomerId=${id}`;
const bookingDetail = `${process.env.REACT_APP_API_DOMAIN}/booking/`;
const getOneBookingUrl = (orderId) => `${process.env.REACT_APP_API_DOMAIN}/Order/${orderId}`;
const changeRegistrationMarkUrl = `${process.env.REACT_APP_API_DOMAIN}/admin/booking/changeRegistrationMark`;
const checkLPNChangeableUrl = (id) =>
  `${process.env.REACT_APP_API_DOMAIN}/booking-action/checkLpnChange/${id}`;
const checkNewLPNChangeableUrl = (id, lpn) =>
  `${process.env.REACT_APP_API_DOMAIN}/booking-action/checkLpnChange/${id}?registrationMark=${lpn}`;
const tempChangeTerminateURL = `${process.env.REACT_APP_API_DOMAIN}/CarPlateChange/terminate`;

// const dateFormat = 'DD MMM YYYY';
function useBookings(page, isIncludeNullOrder = false) {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    user: { id: customerId = '' },
  } = useContext(CustomerContext);
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setLoading] = useState(true);
  const [totalBookings, setTotalBookings] = useState(1);
  const totalPage = Math.floor(totalBookings / 20 + (totalBookings % 20 > 0 ? 1 : 0));

  function getValidTime(time, isEng, type) {
    if (!time) {
      return '';
    }

    const locale = isEng ? 'en' : 'zh-hk';
    const dateFormat = isEng ? 'DD MMM YYYY' : 'YYYY年 MMM DD';
    const momentDate = moment(time).utcOffset(0);

    if (type === 'endDate') {
      return momentDate.format('HH:mm') === '23:59' || momentDate.format('HH:mm') === '00:00'
        ? momentDate.locale(locale).format(dateFormat)
        : momentDate.add(8, 'hour').locale(locale).format(dateFormat);
    }

    return momentDate.format('HH:mm') === '00:00'
      ? momentDate.locale(locale).format(dateFormat)
      : momentDate.add(8, 'hour').locale(locale).format(dateFormat);
  }

  useEffect(() => {
    async function getBookings() {
      const isEng = language === 'en';
      if (typeof customerId !== 'number') return;
      const bookingWithPage = `${process.env.REACT_APP_API_DOMAIN}/Booking?page=${page}&limit=20&sortOrder=descend&CustomerId=${customerId}`;
      setLoading(true);
      try {
        const {
          data: { data, count },
        } = await axios.get(bookingWithPage);

        const bookingData = data
          .filter(({ CarId }) => CarId !== null)
          .map((item) => {
            const {
              status,
              id,
              validFrom,
              validTo,
              ParkingPlanId,
              CarParkId,
              ParkingPlan,
              Order,
              Car,
              CarId,
              type,
              isRenewable,
            } = item;

            const {
              amount,
              subTitle: service,
              chineseSubTitle: chineseService,
              CarPark,
            } = ParkingPlan || {};

            const { name, address, chineseName, chineseAddress } = CarPark || {};
            const { Payment } = Order || {};
            const { updatedAt } = Payment || {};
            const { registrationMark, CarType } = Car || {};
            const { id: CarTypeId } = CarType || {};
            const { ocrStatus } = Order || {};

            const fromDate = getValidTime(validFrom, isEng);
            const toDate = getValidTime(validTo, isEng, 'endDate');
            const period = `${fromDate} - ${toDate}`;
            const lastPaymentDate = getValidTime(updatedAt, isEng);
            return {
              ParkingPlanId,
              CarParkId,
              CarId,
              name: isEng ? name : chineseName,
              address: isEng ? address : chineseAddress,
              status,
              id,
              service: isEng ? service : chineseService,
              registrationMark,
              period,
              validFrom,
              validTo,
              amount,
              lastPaymentDate,
              count,
              ocrStatus,
              type,
              CarTypeId,
              isRenewable,
            };
          });
        setLoading(false);
        setBookings(bookingData);
        setTotalBookings(count);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getBookings();
  }, [customerId, language, page]);

  return { bookings, bookingsLoading, totalPage };
}

function useBooking() {
  const { locations } = usePickupLocations();
  const history = useHistory();
  const [OrderDetail, setOrderDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  async function renew(id) {
    const {
      data: { data },
    } = await axios.get(`${bookingDetail}${id}`);

    const isEng = language === 'en';

    const {
      ParkingPlan,
      CarId,
      PickUpLocationId,
      Car,
      validFrom,
      validTo,
      accountCode,
      CustomerId,
      reference,
      Customer,
    } = data;

    // const momentValidFrom = moment(validFrom)
    //   .utcOffset(8)
    //   .date(parseInt(process.env.REACT_APP_RENEWAL_DATE || '22', 10));
    // const momentValidTo = moment(validFrom).utcOffset(8).endOf('month');
    // const now = moment().utcOffset(8);

    // if (now.isBefore(momentValidFrom, 'day') || now.isAfter(momentValidTo, 'day')) {
    //   return Promise.reject(new Error('DateError'));
    // }

    const momentValidFrom = moment(validFrom)
      .utcOffset(8)
      .date(parseInt(process.env.REACT_APP_RENEWAL_DATE || '22', 10));
    const momentValidTo = moment(validTo).utcOffset(0).endOf('month');
    const momentValidToMonthYear = moment(momentValidTo).utcOffset(0).format('M,YYYY');
    const now = moment().utcOffset(8);

    // if (user?.CustomerId && user?.CustomerId !== CustomerId) {
    //   console.log('error');
    // }

    if (now.format('M,YYYY') !== momentValidToMonthYear) {
      return Promise.reject('DateError');
    }
    const { phone } = Customer || {};
    const {
      id: ParkingPlanId,
      amount: fee,
      subTitle: service,
      chineseSubTitle: chineseService,
      CarPark,
      CarTypeId,
    } = ParkingPlan || {};
    const {
      name: englishName,
      chineseName,
      address: englishAddress,
      chineseAddress,
    } = CarPark || {};
    const { registrationMark } = Car || {};

    const { Order, status } = data;
    const { reference: orderReference } = Order || {};

    const PickUpLocatioObj = locations.filter((location) => location.value === PickUpLocationId)[0];

    const nextMonth = moment().add(1, 'M').startOf('month');
    const from = moment(nextMonth.clone().startOf('month')).format('D/MM/YYYY');
    const to = moment(nextMonth.clone().endOf('month')).format('D/MM/YYYY');
    // const isReserved = service === 'Reserved';
    const renewItemObj = {
      id,
      orderReference,
      accountCode,
      carPark: CarPark,
      ParkingPlanId,
      name: isEng ? englishName : chineseName,
      address: isEng ? englishAddress : chineseAddress,
      fee,
      CarId,
      service: language.toLowerCase().includes('en')
        ? service || chineseService || ''
        : chineseService || service || '',
      registrationMark,
      PickUpLocationId,
      PickUpLocatioObj,
      servicePeriod: `${from} ${t('CarParkDetail.Booking.to')} ${to}`,
      type: 'Renew',
      CustomerId,
      bookingStatus: status,
      reference,
      phone,
      CarTypeId,
      createdDate: moment().toISOString(),
    };

    localStorage.setItem('single', JSON.stringify([renewItemObj]));
    return renewItemObj;
  }

  async function terminate(id) {
    const { status } = await axios.put(`${bookingDetail}${id}`, {
      status: 'terminated',
    });
    return status;
  }

  async function tempChangeTerminate(id) {
    const { status } = await axios.put(`${tempChangeTerminateURL}`, {
      origin: 'web',
      NextBookingId: id,
    });
    return status;
  }

  async function changeCarPark(item) {
    localStorage.setItem('single', JSON.stringify([item]));
    return item;
  }

  async function checkLPNChangeable(id) {
    try {
      const { data } = await axios.get(checkLPNChangeableUrl(id));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function checkNewLPNchangeable(id, lpn) {
    try {
      const { data } = await axios.get(checkNewLPNChangeableUrl(id, lpn));
      return Promise.resolve(data);
    } catch (error) {
      const { data: { error: errorMessage = t('error') } = {} } = error.response || {};

      // if(errorMessage === ''){
      //   return Promise.resolve(t('MyAccount.iMonthly.hourlyPresentReminder') );
      // }

      return Promise.reject(errorMessage);
    }
  }

  async function changeLPN(data) {
    const { id, registrationMark, type, startDate, bookingID, newRegistrationMark, CarId } =
      data || {};

    try {
      setIsLoading(true);

      const {
        isTempChangeable,
        isPermanentChangeable,
        vehicleIsPresent,
        monthlyVehiclePresent,
        hourlyVehiclePresent,
      } = await checkNewLPNchangeable(bookingID, registrationMark);

      if (
        (type === 'temporary' && !isTempChangeable) ||
        (type === 'permanent' && !isPermanentChangeable)
      ) {
        throw t('Myprofile.notAvailbleChangeLPN');
      }

      if (hourlyVehiclePresent) {
        throw t('MyAccount.iMonthly.hourlyPresentReminder');
      }

      if (type === 'permanent') {
        const {
          data: {
            data: {
              ParkingPlan: { id: ParkingPlanId },
              Car: { CarType: { id: CarTypeId = '' } = {} } = {},
            },
          },
        } = await axios.get(`${bookingDetail}${id}`);

        return {
          id,
          registrationMark,
          ParkingPlanId,
          // PickUpLocationId: 2,
          servicePeriod: `${moment(startDate).format('DD/MM/YYYY')} to ${moment(startDate)
            .endOf('month')
            .format('DD/MM/YYYY')}`,
          type: 'changeCar',
          fee: 50,
          CarTypeId,
          createdDate: moment().toISOString(),
        };
      }

      await axios.put(changeRegistrationMarkUrl, {
        registrationMark: newRegistrationMark,
        changeType: 'temporary',
        validFrom: moment(startDate, 'DD/MM/YYYY').toISOString(),
        PreviousBookingId: bookingID,
        currentCar: {
          currentRegistrationMark: registrationMark,
          currentCarId: CarId,
        },
        origin: 'web',
      });

      window.location = `/${language}/customers/account`;

      return true;
    } catch (error) {
      Swal.fire({
        title: error,
        icon: 'error',
        confirmButtonText: t('MyAccount.iMonthly.confirm'),
        confirmButtonColor: '#fd980f',
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  }

  async function GetOneBooking(orderId, popupMessage) {
    const {
      data: { data },
    } = await axios.get(getOneBookingUrl(orderId));

    const isEng = language === 'en';

    const nextMonth = moment().add(1, 'M').startOf('month');
    const from = moment(nextMonth.clone().startOf('month')).format('DD/MM/YYYY');
    const to = moment(nextMonth.clone().endOf('month')).format('DD/MM/YYYY');

    const { Bookings, reference, Payment } = data || {};
    const { accountCode, Car, ParkingPlan, status, type, validFrom } = Bookings[0] || [];
    const { amount } = Payment;
    const { CarPark, chineseSubTitle, subTitle } = ParkingPlan || [];
    const { registrationMark } = Car || [];
    const { name, chineseName } = CarPark || [];

    // const momentValidFrom = moment(validFrom).format('MM');

    // const getNexttoday = moment().add(1, 'M').format('MM');

    // if (momentValidFrom === getNexttoday) {
    //   alert(t('CarParkDetail.Booking.errorMsg'));
    //   history.push(`/${language}/cart`);
    //   return '';
    // }
    setOrderDetail({
      orderReference: reference,
      servicePeriod: `${from} ${t('CarParkDetail.Booking.to')} ${to}`,
      accountCode,
      amount,
      registrationMark,
      CarParkName: isEng ? name : chineseName,
      bookingStatus: status,
      type,
      subTitle: isEng ? subTitle : chineseSubTitle,
    });

    if (popupMessage && accountCode) {
      Swal.fire({
        focusConfirm: false,
        title: popupMessage,
        position: 'center',
        confirmButtonColor: '#fd980f',
        confirmButtonText: t('sevenPayment.close'),
        customClass: {
          title: 'sweetAlretTitle',
          popup: 'sweetAlretPopup',
          header: 'sweetAlretHeader',
          actions: 'sweetAlretActions',
        },
      });
    }
  }

  return {
    renew,
    terminate,
    changeCarPark,
    changeLPN,
    GetOneBooking,
    OrderDetail,
    isLoading,
    checkLPNChangeable,
    tempChangeTerminate,
  };
}

export { useBooking, useBookings };
