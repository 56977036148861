import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import SearchIcon from '../../media/images/search_white.png';
import { useSearch } from '../../models/Search';
import { useDebouncedEffect } from '../../helpers/generalHelper';

const HeaderBar = styled.div`
  background-color: #fd980f;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
`;

const HeaderMaxWidth = styled.div`
  max-width: 1024px;
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: transparent url(${SearchIcon}) right center no-repeat;
  color: #fff;
  font-size: 2rem;
  padding-top: 15px;
  padding-bottom: 15px;
  opacity: 1;
  font-style: italic;
  display: inline-block;
  padding: 0px;
  min-height: 69px;

  &:focus {
    color: #fff;
    box-shadow: none;
  }

  &::placeholder {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
`;

const ResultContainer = styled.div`
  background-color: #dedede;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ResultMaxWidth = styled.div`
  max-width: 1024px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

const ResultInner = styled.div`
  background-color: #fff;
  padding: 50px 15px;
`;

const ResultFor = styled.p`
  display: inline-block;
  width: 100%;
  color: #fd980f;
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const TotleResult = styled.p`
  margin-top: 0.5rem;
  display: inline-block;
  width: 100%;
  color: #444444;
  font-size: 1.125rem;
  line-height: 1;
`;

const ResultRow = styled.div`
  border-top: 1px solid #dedede;
  padding: 30px 15px;
`;

const ResultHref = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const ResultTitle = styled.p`
  font-weight: 700;
  display: inline-block;
  width: 100%;
  color: #444444;
  font-size: 1rem;

  ${ResultHref}:hover & {
    color: #fd980f;
  }
`;

const ResultText = styled.p`
  color: #444444;
  font-size: 0.875rem;
  line-height: 1.5;
  ${ResultHref}:hover & {
    color: #fd980f;
  }
`;

function Search() {
  const { t } = useTranslation();
  const [keyWords, setKeyWords] = useState('');
  const [result, setResult] = useState([]);
  const [resultTitle, setResultTitle] = useState('');
  const { SearchDataBase } = useSearch();
  const { search } = useLocation();
  const { keyword } = useParams();

  function handleSearch(input = '') {
    if (!input) {
      setResultTitle(input);
      setResult([]);
      return;
    }
    if (SearchDataBase) {
      const lowerKeyWord = input.toLowerCase();
      setResultTitle(input);
      setResult(
        SearchDataBase.filter(({ title = '', content = '', subTitle = '' }) => {
          if (
            title.toLowerCase().includes(lowerKeyWord) ||
            content.toLowerCase().includes(lowerKeyWord) ||
            subTitle.toLowerCase().includes(lowerKeyWord)
          ) {
            return true;
          }
          return false;
        })
      );
    }
  }

  useEffect(() => {
    if (keyword) {
      handleSearch(keyword);
      setKeyWords(keyword);
      setResultTitle(keyword);
    }
  }, [keyword, SearchDataBase]);

  return (
    <div style={{ flex: 1 }}>
      <HeaderBar>
        <HeaderMaxWidth>
          <SearchInput
            value={keyWords}
            placeholder={t('Search.search')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e.target.value);
              }
            }}
            onChange={(e) => {
              const value = e.target.value || '';
              setKeyWords(value.replace(/[^A-Za-z0-9\s]+/, ''));
            }}
          />
        </HeaderMaxWidth>
      </HeaderBar>
      <ResultContainer>
        <ResultMaxWidth>
          <ResultInner>
            <ResultFor>
              {t('Search.yourResult')}
              {resultTitle}
            </ResultFor>
            <TotleResult>
              {t('Search.total')}
              {'  '}
              {result.length === 0 ? 0 : result.length}
              {t('Search.result')}
            </TotleResult>
            {result.length > 0 &&
              result.map(({ title, content, link }) => {
                return (
                  <ResultHref href={link}>
                    <ResultRow>
                      <ResultTitle>{title}</ResultTitle>
                      <ResultText dangerouslySetInnerHTML={{ __html: content }} />
                    </ResultRow>
                  </ResultHref>
                );
              })}
          </ResultInner>
        </ResultMaxWidth>
      </ResultContainer>
    </div>
  );
}

export default withRouter(Search);
