import React, { useEffect, useContext, useState, createContext, Suspense, useRef } from 'react';
import './App.css';
import './index.css';
import './assets/css/suneditor.min.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
  Link,
  useParams,
} from 'react-router-dom';
// import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import useMeasure from 'react-use-measure';
import Home from './containers/Home/Home';
import Login from './containers/customers/Login';
import {
  Account,
  IParkVerifySteps,
  ValidateCreditCard,
  ActiveExpired,
  ActiveSuccess,
  ActivatingAccount,
  DeleteAccountTnc,
  DeleteAccountFailed,
  DeleteAccountConfirm,
  DeleteAccountSuccess,
  DeleteAccountSurvey,
  ActiveAlready,
} from './containers/customers/Account';
import PrivacyPolicy from './containers/cslist/PrivacyPolicy';
import Disclaimer from './containers/cslist/Disclaimer';
import BusinessEnquiry from './containers/cslist/BusinessEnquiry/BusinessEnquiry';
import CustomerService from './containers/cslist/CustomerService/CustomerService';
import News from './containers/News/News';
import OurCompany from './containers/OurCompany/OurCompany';
import Awards from './containers/Awards/Awards';
import IHourlyFAQ from './containers/IHourlyFAQ/IHourlyFAQ';
import IMonthlyFAQ from './containers/IMonthlyFAQ/IMonthly';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import MobileApp from './containers/MobileApp/MobileApp';
import { AllCarParksHook, CarParkHook } from './containers/CarPark';
import RegistrationForm from './containers/Registration/RegistrationForm';
import Cart from './containers/Cart/CartHook';
import { useCart, CartContext } from './models/Cart';
import { useCustomer, CustomerContext } from './models/User';
import { useAuth, TokenContext } from './models/Customer';
import ResetPassword from './containers/ForgotPassword/ResetPassword';
import ResetPasswordByEmail from './containers/ForgotPassword/ResetPasswordByEmail';
import PasswordReseted from './containers/ForgotPassword/PasswordReseted';
import SentOutPage from './containers/cslist/SentOutPage';
import ProfileUpdated from './containers/customers/Account/ProfileUpdated';
import Registered from './containers/Registration/Registered';
import IhourlyCart from './containers/IhourlyCart/IhourlyCart';
import ChangeSuccess from './containers/customers/Account/ChangeSuccess';
import PaymentLinkCart from './containers/PaymentLinkCart';
import SevenPayment from './containers/PaymentLink/Seven';
import ChequePayment from './containers/PaymentLink/Cheque';
import FPSPayment from './containers/PaymentLink/FPS';
import Search from './containers/Search';
import QrCode from './containers/QrCode';
import Test from './containers/Test/Test';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import OTP from './containers/OTP';
import SkiDataMonthlyParkerRecord from './containers/SkiDataMonthlyParkerRecord';
import MobileAppProblem from './containers/MobileAppProblem';
import CopyRight from './containers/CopyRight';
import BookingTNC from './containers/BookingTNC';
import IHourlyTNC from './containers/IHourlyTNC';
import useStaticInfo from './models/StaticInfo';
import IMonthlyTNC from './containers/IMonthlyTNC';
import LoadingSprinner from './components/Loading';

function PrivateRoute({ children, ...rest }) {
  const { token } = useContext(TokenContext);
  const { i18n } = useTranslation();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${i18n.language}/customers/login`,
              state: { redirectedFrom: location.pathname },
            }}
          />
        )
      }
    />
  );
}

function LoadingView({ loading }) {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  if (!pathname.includes('PaymentLink')) {
    return null;
  }

  return <LoadingSprinner loading={loading} msg={t('wait')} />;
}

function PrivateRoutewithLogin({ children }) {
  const { token } = useContext(TokenContext);
  const { i18n } = useTranslation();
  return (
    <Route
      render={() =>
        !token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${i18n.language}/customers/account`,
            }}
          />
        )
      }
    />
  );
}

// function RedirectToOldWebsite({ children, path, location, ...routeProps }) {
//   const { token } = useContext(TokenContext);
//   const { i18n } = useTranslation();
//   const { pathname } = location;

//   let replacedPath = pathname.replace(`/${i18n.language}`, '');

//   if (path.includes('searches')) {
//     const params = pathname.replace(`/${i18n.language}/searches/`, '');
//     replacedPath = `/searches/index?keyword=${params}`;
//   }

//   return (
//     <>
//       {token ? (
//         <Route {...routeProps} location={location} path={path} render={() => children} exact />
//       ) : (
//         <Route
//           path={path}
//           exact
//           render={() => {
//             if (i18n.language === 'en') {
//               window.location.href = `${process.env.REACT_APP_OLD_WEBSITE_DOMAIN}/en-us${replacedPath}`;
//             }
//             if (i18n.language === 'zh') {
//               window.location.href = `${process.env.REACT_APP_OLD_WEBSITE_DOMAIN}/zh-hk${replacedPath}`;
//             }
//             return (
//               <div style={{ height: '100%' }}>
//                 <SpinnersClass loading />
//               </div>
//             );
//           }}
//         />
//       )}
//     </>
//   );
// }

function App() {
  const [staticInfo, setStaticInfo] = useState([]);

  const auth = useAuth();
  const customer = useCustomer();
  const generalCart = useCart('general');
  const singleCart = useCart('single');
  const { clearTemporaryCart: singleCartClear } = singleCart;
  const { t, i18n } = useTranslation();
  const { getStaticInfo, StaticInfoContext, getStaticValue } = useStaticInfo();
  const [isLoading, setIsLoading] = useState(false);

  const defaultTimeZone = 'Asia/Hong_Kong';
  moment.tz.setDefault(defaultTimeZone);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('lang') || 'en');
  }, []);

  function ClearTemporaryCart() {
    const { pathname } = useLocation();
    const history = useHistory();
    const [pastRoute, setPastRoute] = useState(pathname);

    history.listen(({ pathname: innerpathname }) => {
      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
      if (localStorage.getItem('single')) {
        const result = JSON.parse(localStorage.getItem('single'));
        if (
          pathname !== innerpathname &&
          result.length > 0 &&
          !innerpathname.includes('cart') &&
          !innerpathname.includes('PaymentLinkCart')
        ) {
          singleCartClear();
        }
      }

      if (pastRoute !== innerpathname) {
        window.scrollTo(0, 0);
      }
    });

    return null;
  }

  function ChangeLang() {
    const { pathname } = useLocation();
    useEffect(() => {
      if (pathname.includes('/en')) {
        localStorage.setItem('lang', 'en');
      }
      if (pathname.includes('/zh')) {
        localStorage.setItem('lang', 'zh');
      }
    }, [pathname]);
    return null;
  }

  useEffect(() => {
    setIsLoading(true);
    getStaticInfo()
      .then((e) => {
        setStaticInfo(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const pathNameData = getStaticValue(
    [
      'Pages-Wilson Parking Mobile App',
      'Pages-Wilson Parking Mobile App - problem',
      'Pages-Copyright',
      'Pages-Privacy Policy',
      'Pages-Disclaimer',
      'Pages-i-MONTHLY Terms & Conditions',
      'Pages-i-HOURLY Parking Service Tutorial & FAQ',
      'Pages-i-MONTHLY Parking Service Tutorial & FAQ',
      'Pages-i-HOURLY Terms & Conditions',
      'Pages-Payment Terms & Conditions',
    ],
    staticInfo
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TokenContext.Provider value={auth}>
        <CustomerContext.Provider value={customer}>
          <CartContext.Provider value={{ generalCart, singleCart }}>
            <StaticInfoContext.Provider value={staticInfo}>
              <div id="main" className="containerFlex" style={{ height: '100%' }}>
                <link
                  href="https://fonts.googleapis.com/css?family=Lato&display=swap"
                  rel="stylesheet"
                />
                <Helmet>
                  <title>{t('header.PageTitle')}</title>
                  <meta name="description" content={t('header.PageTitle')} />
                </Helmet>
                <BrowserRouter forceRefresh={false}>
                  <ClearTemporaryCart />
                  <ChangeLang />
                  <Header />
                  <LoadingView loading={isLoading} />

                  <Switch>
                    {/* NEWS /news/:newsIndex? */}
                    <Route path={`/:${i18n.language}*/customers/login`}>
                      <Login />
                    </Route>

                    <Route path={`/:${i18n.language}*/news/index`}>
                      <News />
                    </Route>

                    <Route path={`/:${i18n.language}*/our-company`}>
                      <OurCompany />
                    </Route>

                    <Route path={`/:${i18n.language}*/awards/index`}>
                      <Awards />
                    </Route>

                    <Route path={`/:${i18n.language}*/customer_enquiries/index`}>
                      <CustomerService />
                    </Route>

                    <Route path={`/:${i18n.language}*/business_enquiries/index`}>
                      <BusinessEnquiry />
                    </Route>

                    <Route path={`/:${i18n.language}*/customers/message/info%3Aenquiry_success`}>
                      <SentOutPage />
                    </Route>

                    <Route path={`/:${i18n.language}*/car_parks/map`}>
                      <AllCarParksHook />
                    </Route>

                    <Route path={`/:${i18n.language}*/car_parks/detail/:carParkId`}>
                      <CarParkHook />
                    </Route>

                    <PrivateRoutewithLogin path={`/:${i18n.language}*/customers/registration`}>
                      <RegistrationForm />
                    </PrivateRoutewithLogin>

                    <Route
                      path={`/:${i18n.language}*/customers/message/info%3Aregistration_success`}
                    >
                      <Registered />
                    </Route>

                    <Route path={`/:${i18n.language}*/customers/forgot-password`}>
                      <ForgotPassword />
                    </Route>
                    <Route path={`/:${i18n.language}*/customers/reset-password/:token?`}>
                      <ResetPassword />
                    </Route>
                    <Route
                      path={`/:${i18n.language}*/customers/message/info%3Asendout_forgot_password_success`}
                    >
                      <ResetPasswordByEmail />
                    </Route>
                    <Route
                      path={`/:${i18n.language}*/customers/message/info%3Areset_password_success`}
                    >
                      <PasswordReseted />
                    </Route>

                    <Route path={`/:${i18n.language}*/customers/message/info%3Aactivate_success`}>
                      <ActiveSuccess />
                    </Route>
                    <Route path={`/:${i18n.language}*/customers/message/info%3Aactivate_already`}>
                      <ActiveAlready />
                    </Route>

                    <Route path={`/:${i18n.language}*/customers/ActivatingAccount/:token?`}>
                      <ActivatingAccount />
                    </Route>

                    <Route path={`/:${i18n.language}*/customers/DeleteAccountTnc/:token?`}>
                      <DeleteAccountTnc />
                    </Route>
                    <Route path={`/:${i18n.language}*/customers/DeleteAccountSurvey/:token?`}>
                      <DeleteAccountSurvey />
                    </Route>
                    <Route path={`/:${i18n.language}*/customers/DeleteAccount/failed`}>
                      <DeleteAccountFailed />
                    </Route>
                    {/* <Route path={`/:${i18n.language}*\customers/DeleteAccount/confirm`}>
                  <DeleteAccountConfirm />
                </Route> */}
                    <Route path={`/:${i18n.language}*/customers/DeleteAccount/success`}>
                      <DeleteAccountSuccess />
                    </Route>
                    <Route path={`/:${i18n.language}*/OTP`}>
                      <OTP />
                    </Route>
                    <Route path={`/:${i18n.language}*/SkiDataMonthlyParkerRecord`}>
                      <SkiDataMonthlyParkerRecord />
                    </Route>

                    {/* <Route path={`/:${i18n.language}*\/IhourlyCart/:step?`}> */}
                    {/*  <IhourlyCart /> */}
                    {/* </Route> */}

                    <Route path={`/:${i18n.language}*/PaymentLink/cart/:step?`}>
                      <PaymentLinkCart />
                    </Route>
                    <Route path={`/:${i18n.language}*/PaymentLink/seven/:orderId?`}>
                      <SevenPayment />
                    </Route>
                    <Route path={`/:${i18n.language}*/PaymentLink/cheque/:orderId?`}>
                      <ChequePayment />
                    </Route>
                    <Route path={`/:${i18n.language}*/PaymentLink/fps/:orderId?`}>
                      <FPSPayment />
                    </Route>
                    <Route path={`/:${i18n.language}*/QrCode/:accountCode?`}>
                      <QrCode />
                    </Route>

                    <Route path={`/:${i18n.language}*/searches/:keyword?`}>
                      <Search />
                    </Route>

                    <PrivateRoute path={`/:${i18n.language}*/customers/account`}>
                      <Account />
                    </PrivateRoute>

                    <PrivateRoute path={`/:${i18n.language}*/customers/ProfileUpdated`}>
                      <ProfileUpdated />
                    </PrivateRoute>

                    <Route path={`/:${i18n.language}*/cart/:step(4)`} exact>
                      <Cart />
                    </Route>

                    <PrivateRoute path={`/:${i18n.language}*/cart/:step?`}>
                      <Cart />
                    </PrivateRoute>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-Wilson Parking Mobile App - problem']}`}
                    >
                      <MobileAppProblem />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-Payment Terms & Conditions']}`}
                    >
                      <BookingTNC />
                    </Route>

                    <Route path={`/:${i18n.language}*/${pathNameData['Pages-Copyright']}`}>
                      <CopyRight />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-i-HOURLY Terms & Conditions']}`}
                    >
                      <IHourlyTNC />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-i-MONTHLY Terms & Conditions']}`}
                    >
                      <IMonthlyTNC />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-i-HOURLY Parking Service Tutorial & FAQ']}`}
                    >
                      <IHourlyFAQ />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-i-MONTHLY Parking Service Tutorial & FAQ']}`}
                    >
                      <IMonthlyFAQ />
                    </Route>

                    <Route path={`/:${i18n.language}*/${pathNameData['Pages-Privacy Policy']}`}>
                      <PrivacyPolicy />
                    </Route>

                    <Route path={`/:${i18n.language}*/${pathNameData['Pages-Disclaimer']}`}>
                      <Disclaimer />
                    </Route>

                    <Route
                      path={`/:${i18n.language}*/${pathNameData['Pages-Wilson Parking Mobile App']}`}
                    >
                      <MobileApp />
                    </Route>

                    <Route path={`/:${i18n.language}*`}>
                      <Home />
                    </Route>
                  </Switch>
                  <Footer />
                </BrowserRouter>
              </div>
            </StaticInfoContext.Provider>
          </CartContext.Provider>
        </CustomerContext.Provider>
      </TokenContext.Provider>
    </Suspense>
  );
}

export default App;
