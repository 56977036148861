import { useRef, useState, useEffect, useCallback } from 'react';

export default function useCheckScrollToFooter() {
  const footerElement = useRef();

  const [isScrollToFooter, setIsScrollToFooter] = useState(false);

  function handleScroll() {
    if (!footerElement.current) {
      return;
    }

    setIsScrollToFooter(
      Math.round(
        footerElement.current.getBoundingClientRect().top -
          (window.innerHeight - footerElement.current.scrollHeight)
      ) <= footerElement.current.scrollHeight
    );
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [footerElement?.current]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];

    if (target.isIntersecting) {
      setIsScrollToFooter(true);
    } else {
      setIsScrollToFooter(false);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (footerElement.current) {
      observer.observe(footerElement.current);
    }
  }, [handleObserver, footerElement]);

  return {
    isScrollToFooter,
    footerElement,
  };
}
