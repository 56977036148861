import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuIcon from './Icon';
import CartItem from './CartItem';

const HeaderIcon = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-left: 3px;

  @media (max-width: 980px) {
    margin-top: 6px;
    margin-left: 0px;
    height: 1rem;
    margin-bottom: 5px;
  }
`;

function MenuItem(props) {
  const {
    title,
    title2,
    link = '/',
    icon,
    hover,
    dropdown,
    staticContext,
    TopTitle,
    href,
    engLink,
    chineseLink,
    onClick,
    state,
    ...rest
  } = props;
  const [image, setImage] = useState(icon);
  const { t, i18n } = useTranslation();

  if (engLink?.includes('http' || engLink?.includes('https'))) {
    return (
      <a
        target="_blank"
        href={i18n.language === 'en' ? engLink : chineseLink}
        rel="noreferrer"
        className="menu-item"
      >
        <span>{t(title)}</span>
      </a>
    );
  }

  if (link === '/cart') {
    return <CartItem {...props} />;
  }

  if (TopTitle === true) {
    return (
      <li className="menu-item-TopTitle">
        <Link className="menu-link-TopTitle" to={link} {...rest}>
          <span className="menu-item-label-TopTitle">{t(title)}</span>
        </Link>
      </li>
    );
  }

  if (!icon) {
    return (
      <li className="menu-item">
        <Link className="menu-link" to={{ pathname: link, state }} {...rest}>
          <span className="menu-item-label">
            {t(title)}
            {t(title2)}
          </span>
        </Link>
      </li>
    );
  }

  if (!hover) {
    return (
      <li className="menu-item">
        <Link className="menu-link" to={link} {...rest}>
          <MenuIcon icon={icon} />
          <span className="menu-item-label">{t(title)}</span>
        </Link>
      </li>
    );
  }

  return (
    <div
      className="menu-item"
      style={{
        display: 'flex',
        flexDirection: dropdown && 'column',
        position: 'relative',
      }}
    >
      <Link
        className="menu-link"
        to={link}
        onMouseOver={() => {
          setImage(hover);
        }}
        onMouseOut={() => {
          setImage(icon);
        }}
        onFocus={() => {
          setImage(hover);
        }}
        onBlur={() => {
          setImage(icon);
        }}
        {...rest}
      >
        <MenuIcon icon={image} />
        <HeaderIcon>
          <div>
            <span className="menu-item-label">
              {t(title)}
              {t(title2)}
            </span>
          </div>
        </HeaderIcon>
      </Link>
    </div>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hover: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withRouter(MenuItem);
