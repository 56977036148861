import React, { useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TabContainer from '../../../components/General/TabContainer';
import IMonthly from './IMonthly/index';
import MyProfileTab from './MyprofileTab/MyProfileTab';
import PaymentRecords from './PaymentRecordshooks';
import WaitingList from './WatingList';

const PageContainer = styled.div`
  background: #dedede;
  padding-bottom: 30px;
  position: relative;
  flex: 1;
`;

const TitleFlex = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
`;

const TitleRow = styled.div`
  width: 1024px;
  padding-right: 12px;
  padding-left: 12px;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #dedede;
`;

const Title = styled.span`
  line-height: 80px;
  color: #444;
  font-size: 2rem;
  font-weight: 400;
`;

const InnerWidth = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

const Inner = styled.div`
  background: #fff;
`;

const Account = () => {
  const { t } = useTranslation();
  const { hash = '' } = useLocation();

  const tabs = useMemo(
    () => [
      {
        title: 'TabContainer.iMONTHLY',
        key: 'i_monthly',
        component: <IMonthly />,
      },
      // {
      //   title: 'TabContainer.waitingList',
      //   key: 'waitingList',
      //   component: <WaitingList />,
      // },
      {
        title: 'TabContainer.PAYMENT_RECORDS',
        key: 'payment_records',
        component: <PaymentRecords />,
      },
      {
        title: 'TabContainer.MY_PROFILE',
        key: 'my_profile',
        component: <MyProfileTab />,
      },
    ],
    []
  );

  const activeKey = useMemo(
    () =>
      hash
        ? tabs.find(({ key }) => key === hash.replace('#', ''))?.key || 'i_monthly'
        : 'i_monthly',
    [hash]
  );

  return (
    <PageContainer>
      <TitleFlex>
        <TitleRow>
          <Title>{t('MyAccountTap.MyAccount')}</Title>
        </TitleRow>
      </TitleFlex>

      <Container>
        <InnerWidth>
          <Inner>
            <TabContainer tabs={tabs} activeKey={activeKey} />
          </Inner>
        </InnerWidth>
      </Container>
    </PageContainer>
  );
};

export default withRouter(Account);
