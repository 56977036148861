import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useCarParks } from '../../../models/CarPark';
import Map from '../../../components/General/Map';
import * as icon from '../../../media/icons';
import MarkerView from './MarkerView';
import MobileInfoDisplay from './MobileInfoDisplay';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(189, 109, 1, 1);
  padding-bottom: 20px;
  height: 570px;

  @media (max-width: 850px) {
    padding-bottom: 0px;
    height: 550px;
  }
  /* 
  .gm-style-iw-chr {
    height: 0px;
    position: absolute;
    top: -10px;
    right: -10px;
  } */

  .gm-ui-hover-effect {
    span {
      display: none !important;
    }
  }
`;

const Width = styled.div`
  border: 3px solid #fff;
  width: 1024px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const NoteBar = styled.div`
  background: #fff;
  right: 50px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 200px;
  z-index: 10;
  position: absolute;
  max-height: 38px;
  bottom: 20px;
`;

const Text = styled.span`
  margin-left: 5px;
  color: #888;
  font-size: 0.82rem;
  align-self: center;
`;

const Icon = styled.img`
  width: 10%;
`;

const containerStyle = {
  height: '100%',
};

function HomeMap() {
  const { t } = useTranslation();
  const { carParks } = useCarParks();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [zoom] = useState(12);
  const [center] = useState({ lat: 22.302219, lng: 114.174637 });

  return (
    <Container>
      <Width>
        <Map containerStyle={containerStyle} center={center} zoom={zoom} markers={carParks}>
          {selectedMarker && <MobileInfoDisplay selectedMarker={selectedMarker} />}
          {carParks.map((marker) => {
            return (
              <MarkerView
                key={marker.id}
                marker={marker}
                selectedMarker={selectedMarker}
                onMarkerClick={setSelectedMarker}
              />
            );
          })}
          {/* <NoteBar>
            <Icon src={icon.markerBlue} />
            <Text>{t('Map.i-Monthly')}</Text>
          </NoteBar> */}
        </Map>
      </Width>
    </Container>
  );
}

export default HomeMap;
