import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '@rehooks/window-size';

const Icon = styled.img`
  width: 320px;
  max-width: 45%;
  /* height: 200px; */
  position: fixed;
  right: 20px;
  object-fit: contain;
  cursor: pointer;
  z-index: 101;
  bottom: 20px;
`;

export default function FloatingIcon({ data }) {
  const [isScrollToFooter, setIsScrollToFooter] = useState(false);
  const { innerWidth } = useWindowSize();

  const imageUrl = `${process.env.REACT_APP_CP_MEDIA_DOMAIN.replace('static', '')}${
    data?.floating_icon_url
  }`;

  const footerElement = window.document.getElementById('footer');

  function handleScroll() {
    if (!footerElement) {
      return;
    }

    const isScrollToFooter =
      Math.round(
        footerElement.getBoundingClientRect().top -
          (window.innerHeight - footerElement.scrollHeight)
      ) <= footerElement.scrollHeight;

    setIsScrollToFooter(isScrollToFooter);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!data?.floating_icon_url) {
    return null;
  }

  return (
    <Icon
      onClick={() => {
        window.open(data?.destination_url, '_blank');
      }}
      src={imageUrl}
      style={{
        // bottom: isScrollToFooter
        //   ? innerWidth < 980
        //     ? notiBarHeight + 10
        //     : 20
        //   : innerWidth < 980 && !isEmpty(announcement)
        //   ? 50
        //   : 20,
        // position: isScrollToFooter ? 'absolute' : 'fixed',

        bottom: isScrollToFooter ? 20 : innerWidth < 980 ? 50 : 20,
      }}
    />
  );
}
